
/*---------------------------
  Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,600,700|Open+Sans:300,400,600,700');
/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  color:#333;
}

@media (max-width: 767px) {
  body {
    font-size: 15px;
  }
}

p {
  color: #666;
  line-height: 30px;
  font-size:16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Josefin Sans', sans-serif;
  color: #353535;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0!important;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

a:hover {
  text-decoration: none!important;
}

img {
  max-width: 100%;
}

