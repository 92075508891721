img {
    max-width: 100%;
}
.poa {
    position: absolute;
    z-index: 1;
}
.zI-1 {
    z-index: -1;
}
.Zi-1 {
    z-index: 1;
}
.L-0 {
    left: 0;
}
.R-0 {
    right: 0;
}
.previewArea {
    flex: 1;
    background: #f5f4ff;
}
@media (max-width: 991px) {
    .previewArea {
        overflow: hidden;
   }
}
.previewArea h1, .previewArea h2, .previewArea h3, .previewArea h4, .previewArea h5, .previewArea h6 {
    color: #444;
}
.previewHeaderArea {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;
    display: flex;
    align-items: center;
    padding: 25px 15px;
}
@media (max-width: 991px) {
    .previewHeaderArea {
        display: none;
   }
}
.previewHeaderArea .logo img {
    height: 30px;
}
.previewHeaderArea .previewMainmenu {
    display: flex;
    padding: 0;
    height: 100%;
    justify-content: flex-end;
}
.previewHeaderArea .previewMainmenu li {
    padding: 0;
    width: unset;
}
.previewHeaderArea .previewMainmenu li:hover a {
    color: #fff;
}
.previewHeaderArea .previewMainmenu li:hover a::before {
    width: 100%;
}
.previewHeaderArea .previewMainmenu li a {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    padding: 0 15px;
    display: block;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    text-transform: capitalize;
    letter-spacing: 0;
}
.previewHeaderArea .previewMainmenu li a::before {
    position: absolute;
    left: 0;
    top: -28px;
    width: 0;
    height: 4px;
    background: #fff;
    content: "";
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
}
.previewHeaderArea .previewMainmenu li a.active::before {
    width: 100%;
}
.previewHeroArea {
    position: relative;
    background: url(./images/hero1.png) no-repeat center / cover;
    min-height: 965px;
    display: flex;
    z-index: 9;
    align-items: center;
}
@media (min-width: 992px) and (max-width: 1700px) {
    .previewHeroArea {
        min-height: 727px;
   }
    .previewHeroArea:before {
        height: calc(100% + 50px) !important;
   }
}
@media (min-width: 992px) and (max-width: 1200px) {
    .previewHeroArea {
        padding-top: 150px;
        display: block;
   }
}
@media (max-width: 991px) {
    .previewHeroArea {
        display: block;
        padding-top: 100px;
        min-height: 1115px;
   }
}
@media (max-width: 450px) {
    .previewHeroArea {
        min-height: 870px;
   }
}
.previewHeroArea::before {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1000px;
    content: "";
    z-index: -9;
    top: 0;
}
.previewHeroArea .heroContent {
    padding: 0 15px;
}
@media (max-width: 1700px) {
    .previewHeroArea .heroContent {
        width: 100%;
   }
}
.previewHeroArea .heroContent h2 {
    font-weight: 600;
    font-size: 53px;
    line-height: 68px;
    color: #fff !important;
    margin-bottom: 30px;
    text-transform: uppercase;
}
@media (max-width: 1700px) {
    .previewHeroArea .heroContent h2 {
        font-size: 50px;
        line-height: 62px;
   }
}
@media (max-width: 767px) {
    .previewHeroArea .heroContent h2 {
        font-size: 26px;
        line-height: 43px;
   }
}
.previewHeroArea .heroContent p {
    font-size: 16px;
    line-height: 26px;
    color: #fff !important;
    margin-bottom: 40px !important;
    padding-right: 50px;
}
@media (max-width: 767px) {
    .previewHeroArea .heroContent p {
        padding-right: 0;
   }
}
.previewHeroArea .heroContent a {
    height: 60px;
    width: 190px;
    border-radius: 50px;
    background: #fff;
    font-size: 16px;
    color: #3b53db;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}
.previewHeroArea .heroImage {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 840px;
    z-index: -1;
}
@media (max-width: 1700px) {
    .previewHeroArea .heroImage {
        width: 640px;
   }
}
@media (max-width: 1100px) {
    .previewHeroArea .heroImage {
        width: 520px;
   }
}
@media (max-width: 991px) {
    .previewHeroArea .heroImage {
        width: 100%;
   }
}
.previewHeroArea .heroImage img {
    width: 1030px;
}
.previewHeroArea .particaleWrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 850px;
    z-index: -9;
    opacity: 0.5;
}
@media (max-width: 1700px) {
    .previewHeroArea .particaleWrapper {
        height: 725px;
   }
}
@media (max-width: 991px) {
    .previewHeroArea .particaleWrapper {
        min-height: 1115px;
   }
}
@media (max-width: 450px) {
    .previewHeroArea .particaleWrapper {
        min-height: 870px;
   }
}
.previewSectionTitle {
    text-align: center;
    margin-bottom: 50px;
}
.previewSectionTitle h2 {
    font-size: 30px;
    position: relative;
    font-weight: 700;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
@media (min-width: 768px) and (max-width: 991px) {
    .previewSectionTitle h2 {
        font-size: 28px;
   }
}
@media (max-width: 767px) {
    .previewSectionTitle h2 {
        font-size: 26px;
   }
}
.previewSectionTitle h2::before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #4ac93d 0%, rgba(134, 126, 255, 0.1) 68.33%);
    border-radius: 10px;
    bottom: 0;
    content: "";
}
.previewSectionTitle p {
    font-size: 18px;
    padding: 0 250px;
    margin-bottom: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
    .previewSectionTitle p {
        padding: 0 100px;
   }
}
@media (max-width: 767px) {
    .previewSectionTitle p {
        padding: 0;
   }
}
.previewWhoWeAreArea {
    padding: 100px 0 150px;
    position: relative;
}
@media (min-width: 992px) and (max-width: 1200px) {
    .previewWhoWeAreArea {
        padding: 50px 0 70px;
   }
}
@media (min-width: 768px) and (max-width: 991px) {
    .previewWhoWeAreArea {
        padding: 20px 0 80px;
   }
}
@media (max-width: 767px) {
    .previewWhoWeAreArea {
        padding: 30px 0 70px;
   }
}
.previewWhoWeAreArea .whoWeAreContetnt {
    padding: 10px;
}
.previewWhoWeAreArea .whoWeAreContetnt li {
    padding: 0;
    display: block;
    margin-bottom: 30px;
}
@media (max-width: 1700px) {
    .previewWhoWeAreArea .whoWeAreContetnt li {
        margin-bottom: 20px;
   }
}
.previewWhoWeAreArea .whoWeAreContetnt li:last-child {
    margin-bottom: 0;
}
.previewWhoWeAreArea .whoWeAreContetnt li h4 {
    font-size: 24px;
    line-height: 25px;
    color: #1f2b35;
    font-weight: 700;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
@media (max-width: 1700px) {
    .previewWhoWeAreArea .whoWeAreContetnt li h4 {
        font-size: 22px;
   }
}
@media (max-width: 767px) {
    .previewWhoWeAreArea .whoWeAreContetnt li h4 {
        font-size: 21px;
   }
}
.previewWhoWeAreArea .whoWeAreContetnt li h4 img {
    margin-right: 15px;
}
@media (max-width: 1700px) {
    .previewWhoWeAreArea .whoWeAreContetnt li h4 img {
        height: 20px;
   }
}
.previewWhoWeAreArea .whoWeAreContetnt li p {
    font-size: 15px;
    font-weight: 500;
    line-height: 28px;
    text-align: justify;
    margin-bottom: 0;
}
@media (max-width: 1700px) {
    .previewWhoWeAreArea .whoWeAreContetnt li p {
        font-size: 15px;
        line-height: 25px;
   }
}
.previewWhoWeAreArea .whoWeAreImg {
    position: absolute;
    left: 0;
    bottom: 95px;
    width: 46%;
    z-index: 1;
}
@media (min-width: 1201px) and (max-width: 1599px) {
    .previewWhoWeAreArea .whoWeAreImg {
        bottom: auto;
        top: 250px;
   }
}
@media (max-width: 991px) {
    .previewWhoWeAreArea .whoWeAreImg {
        position: static;
        width: 100%;
        margin-bottom: 30px;
   }
}
.previewAwesomeFeatureArea {
    background: #fbfbff;
    padding: 90px 10px 60px;
}
@media (min-width: 992px) and (max-width: 1200px) {
    .previewAwesomeFeatureArea {
        padding: 80px 10px;
   }
}
@media (min-width: 768px) and (max-width: 991px) {
    .previewAwesomeFeatureArea {
        padding: 80px 10px;
   }
}
@media (max-width: 767px) {
    .previewAwesomeFeatureArea {
        padding: 60px 10px;
   }
}
.previewAwesomeFeatureArea .awesomeFeaturedWrap {
    text-align: center;
    background: #fff;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    padding: 50px 20px;
    transition: all 0.4s ease;
    margin-bottom: 30px;
}
.previewAwesomeFeatureArea .awesomeFeaturedWrap:hover {
    box-shadow: 0 10px 50px rgba(0, 0, 0, .1);
    transform: translateY(-10px);
    border-color: #fff;
}
.previewAwesomeFeatureArea .awesomeFeaturedWrap .awesomeFeaturedIcon {
    position: relative;
    width: 70px;
    height: 70px;
    margin: auto;
}
.previewAwesomeFeatureArea .awesomeFeaturedWrap .awesomeFeaturedIcon img {
    width: 70px;
    position: absolute;
    left: 0;
    top: 0;
}
.previewAwesomeFeatureArea .awesomeFeaturedWrap .awesomeFeaturedIcon img.awesomeFeaturedIconHoverImg {
    visibility: hidden;
}
.previewAwesomeFeatureArea .awesomeFeaturedWrap h3 {
    font-size: 24px;
    color: #444;
    margin-top: 15px;
    margin-bottom: 20px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
}
.previewAwesomeFeatureArea .awesomeFeaturedWrap P {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
}
.previewFeaturedArea {
    padding: 100px 10px;
}
@media (min-width: 992px) and (max-width: 1200px) {
    .previewFeaturedArea {
        padding: 80px 10px;
   }
}
@media (min-width: 768px) and (max-width: 991px) {
    .previewFeaturedArea {
        padding: 80px 10px;
   }
}
@media (max-width: 767px) {
    .previewFeaturedArea {
        padding: 60px 10px;
   }
}
.previewFeaturedArea.pt0 {
    padding-top: 0;
}
.previewFeaturedArea .featuredContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
@media (max-width: 767px) {
    .previewFeaturedArea .featuredContent {
        margin-bottom: 50px;
   }
}
.previewFeaturedArea .featuredContent h2 {
    font-size: 30px;
    line-height: 39px;
    margin-bottom: 25px;
}
@media (max-width: 1100px) {
    .previewFeaturedArea .featuredContent h2 {
        font-size: 27px;
   }
}
@media (max-width: 767px) {
    .previewFeaturedArea .featuredContent h2 {
        font-size: 23px;
   }
}
.previewFeaturedArea .featuredContent p {
    font-size: 15px;
    font-weight: 500;
    text-align: justify;
    margin-bottom: 35px !important;
}
@media (max-width: 1100px) {
    .previewFeaturedArea .featuredContent p {
        font-size: 15px;
        line-height: 24px;
        text-align: left;
   }
}
.previewFeaturedArea .featuredContent a {
    width: 190px;
    height: 60px;
    background: linear-gradient(241.54deg, #3b53db 7.75%, #5470e8 70.83%);
    border-radius: 50px;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: capitalize;
}
.previewFeaturedArea .featuredImg {
    position: relative;
    z-index: 1;
}
@media (max-width: 991px) {
    .previewFeaturedArea .featuredImg {
        margin: 50px 0;
   }
}
.previewFeaturedArea .featuredImg img {
    width: 100%;
}
.previewFeaturedArea .featuredImg.featuredImgOne img {
    height: 600px;
    object-fit: contain;
}
@media (max-width: 991px) {
    .previewFeaturedArea .featuredImg.featuredImgOne img {
        height: 490px;
   }
}
.previewFeaturedArea .featuredImg.featuredImgTwo {
    text-align: center;
}
.previewFeaturedArea .featuredImg.featuredImgTwo img {
    width: 550px;
}
.previewFeaturedArea .featuredImg.featuredImgTwo .pshape2 {
    bottom: -10px;
    right: 0;
}
.previewFeaturedArea .featuredImg.featuredImgTwo .pshape2 img {
    height: 450px;
    width: unset;
}
.previewFeaturedArea .featuredImg.featuredImgTwo .pshape3 {
    bottom: 0;
    left: -10px;
}
.previewFeaturedArea .featuredImg.featuredImgTwo .pshape3 img {
    height: 450px;
    width: unset;
}
.previewFeaturedArea .featuredImg.featuredImgTwo .pshape4 {
    top: -90px;
}
.previewFeaturedArea .featuredImg.featuredImgTwo .pshape4 img {
    height: unset;
    width: unset;
}
@media (max-width: 991px) {
    .previewFeaturedArea .featuredImg.featuredImgTwo .pshape4 img {
        height: 490px;
   }
}
.previewFeaturedArea .featuredImg.featuredImgThree {
    text-align: center;
}
.previewFeaturedArea .featuredImg.featuredImgThree img.img {
    width: 500px;
}
@media (max-width: 1100px) {
    .previewFeaturedArea .featuredImg.featuredImgThree img.img {
        width: 400px;
   }
}
@media (max-width: 450px) {
    .previewFeaturedArea .featuredImg.featuredImgThree img.img {
        width: 100%;
   }
}
@media (max-width: 991px) {
    .previewFeaturedArea .featuredImg.featuredImgThree .pshape4 img {
        height: 450px;
   }
}
.previewDemoArea {
    background: #fbfbfb;
    padding: 85px 10px 55px;
    position: relative;
}
.previewDemoArea .demoWrapper {
    background: #fff;
    box-shadow: 1px 0 15px rgba(0, 0, 0, .1);
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    transition: all 0.4s ease;
}
.previewDemoArea .demoWrapper::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(241.54deg, #3b53db 7.75%, #5470e8 70.83%);
    content: "";
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease;
}
.previewDemoArea .demoWrapper .demoInfo {
    position: absolute;
    left: 15px;
    top: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.6s ease;
    opacity: 0;
    visibility: hidden;
}
.previewDemoArea .demoWrapper .demoInfo::before, .previewDemoArea .demoWrapper .demoInfo::after {
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
    content: "";
    z-index: -1;
    transform: scaleY(0);
    transition: all 0.4s ease;
    transform-origin: top;
}
.previewDemoArea .demoWrapper .demoInfo::after {
    top: auto;
    bottom: 0;
    transform-origin: bottom;
}
.previewDemoArea .demoWrapper .demoInfo:hover {
    color: #5d5e5e;
}
.previewDemoArea .demoWrapper:hover {
    box-shadow: 0 10px 50px rgba(0, 0, 0, .1);
    transform: translateY(-10px);
}
.previewDemoArea .demoWrapper:hover::before {
    opacity: 0.7;
    visibility: visible;
}
.previewDemoArea .demoWrapper:hover .demoInfo {
    opacity: 1;
    visibility: visible;
}
.previewDemoArea .demoWrapper:hover .demoInfo::before, .previewDemoArea .demoWrapper:hover .demoInfo::after {
    transform: scaleY(1);
}
.previewDemoArea .demoWrapper .demoImg {
    position: relative;
}
.previewDemoArea .demoWrapper .demoImg span {
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 25px;
    line-height: 25px;
    text-transform: uppercase;
    background: linear-gradient(241.54deg, #867eff 7.75%, #6258fb 70.83%);
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
}
.previewDemoArea .demoWrapper .demoImg a {
    display: block;
}
.previewDemoArea .demoWrapper .demoImg a img {
    width: 100%;
}
.previewDemoArea .demoWrapper .demoContent {
    padding: 30px;
    text-align: center;
    border-top: 2px solid #666;
}
.previewDemoArea .demoWrapper .demoContent h3 {
    font-size: 22px;
    text-transform: capitalize;
}
.previewFooterArea {
    background: linear-gradient(241.54deg, #3b53db 7.75%, #5470e8 70.83%);
    padding: 50px 0 30px;
    text-align: center;
}
@media (max-width: 767px) {
    .previewFooterArea {
        padding: 20px 0;
   }
}
.previewFooterArea p {
    color: #fff;
}
.previewFooterArea p span {
    font-weight: 600;
    margin-left: 5px;
}
.pshape2 {
    right: 30px;
}
.pshape3 {
    bottom: 35px;
}
.bounceAnimation {
    animation: bounce 3s linear infinite;
}
.bounceAnimationTop {
    animation: bounceTop 3s linear infinite;
}
.circleAnimation {
    animation: circle 60s linear infinite;
}
@-webkit-keyframes bounce {
    0% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
   }
    50% {
        -webkit-transform: translateY(15px) translateX(7);
        transform: translateY(15px) translateX(7);
   }
    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
   }
}
@keyframes bounce {
    0% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
   }
    50% {
        -webkit-transform: translateY(15px) translateX(7px);
        transform: translateY(15px) translateX(7px);
   }
    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
   }
}
@-webkit-keyframes bounceTop {
    0% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
   }
    50% {
        -webkit-transform: translateY(-15px) translateX(-7);
        transform: translateY(-15px) translateX(-7);
   }
    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
   }
}
@keyframes bounceTop {
    0% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
   }
    50% {
        -webkit-transform: translateY(-15px) translateX(-7px);
        transform: translateY(-15px) translateX(-7px);
   }
    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
   }
}
@-webkit-keyframes circle {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
   }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
   }
}
@keyframes circle {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
   }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
   }
}
.demoTabMenu {
    text-align: center;
    width: 100%;
}
.demoTabMenu > div {
    display: inline-block !important;
}
.demoTabMenu button {
    outline: none;
    box-shadow: none;
}
.demoTabMenu button span {
    font-size: 15px;
}
.demoTabMenu > div > span {
    background: #2dd566;
}


@media(max-width:767px){
    .demoTabMenu .MuiTabs-centered {
        justify-content: center;
        flex-wrap: wrap;
    }
}


.eCommerce-area{
    background: #f5f4ff;
}